import React, {useState} from 'react'
// import Image from 'gatsby-image'
import {navigate} from 'gatsby-link'
import {Link} from 'gatsby'
import Recaptcha from 'react-recaptcha'

import PortableText from './portableText'
import Container from './container'

import styles from './page.module.css'

const Page = ({title, subTitle, _rawBody, pageSidebar, thankYou, emailto, subject, enableForm}) => {
  function encode (data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  // State
  const [formValues, setFormValues] = useState([])
  const [isVerified, setIsVerified] = useState(false)
  const [formErrors, setFormErrors] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState(null)

  // Google Recaptcha
  let recaptchaLoaded = function () {
    console.log('Loaded')
  }
  let expiredCallback = function () {
    console.log('expired')
    setIsVerified(false)
    setRecaptchaResponse(null)
  }
  let verifyCallback = function (response) {
    console.log(response)
    if (response) {
      setIsVerified(true)
      setRecaptchaResponse(response)
    } else {
      setIsVerified(false)
      setRecaptchaResponse(null)
    }
  }

  const handleChange = e => {
    setFormValues({...formValues, [e.target.name]: e.target.value})
  }
  const handleSubmit = e => {
    e.preventDefault()
    // console.log({e})

    if (isVerified && formValues.name && formValues.email && formValues.message) {
      console.log('valid form')
      console.log({formValues})
      setFormErrors(false)

      const form = e.target
      fetch('/.netlify/functions/email', {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: encode({
          'to': emailto,
          'from': 'wax@screaminpeach.com',
          'subject': subject,
          ...formValues,
          recaptcha: recaptchaResponse
        })
      })
        .then(() => navigate(form.getAttribute('action')))
        .catch(error => console.error(error))
    } else {
      console.log('invalid form')
      setFormErrors(true)
    }
  }

  return (
    <>
      <article className={styles.root} >

        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
            <h4 className={styles.pagesubTitle}>{subTitle}</h4>
          </div>
          <div>
            <div className={styles.mainContent}>
              <div style={{paddingRight: '30px', width: '100%'}}>
                {_rawBody && <PortableText blocks={_rawBody} />}
                <div>
                  {enableForm && (
                    <form
                      name={'ScreaminPeach.com Contact Form'}
                      method='post'
                      action={thankYou}
                      onSubmit={handleSubmit}
                    >

                      <div hidden>
                        <label>
                    Don’t fill this out:{' '}
                          <input name={'bot-field'} onChange={handleChange} />
                        </label>
                      </div>

                      <div className='field'>
                        <label className={styles.customLabel} htmlFor={'name'}>
                    Your name
                        </label>
                        <div className='control'>
                          <input
                            className={styles.customInput}
                            type={'text'}
                            name={'name'}
                            onChange={handleChange}
                            id={'name'}
                            required
                          />
                        </div>
                      </div>

                      <div className='field'>
                        <label className={styles.customLabel} htmlFor={'email'}>
                    Email
                        </label>
                        <div className='control'>
                          <input
                            className={styles.customInput}
                            type={'email'}
                            name={'email'}
                            onChange={handleChange}
                            id={'email'}
                            required
                          />
                        </div>
                      </div>

                      <div className='field'>
                        <label className={styles.customLabel} htmlFor={'phone'}>
                    Phone
                        </label>
                        <div className='control'>
                          <input
                            className={styles.customInput}
                            type={'text'}
                            name={'phone'}
                            onChange={handleChange}
                            id={'phone'}

                          />
                        </div>
                      </div>

                      <div className='field'>
                        <label className={styles.customLabel} htmlFor={'message'}>
                    Message
                        </label>
                        <div className='control'>
                          <textarea
                            className={styles.customInput}
                            name={'message'}
                            onChange={handleChange}
                            id={'message'}
                            required
                            rows='4'
                          />
                        </div>
                      </div>

                      <div>
                        <br />

                        <Recaptcha
                          sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_PUBLIC}
                          render='explicit'
                          verifyCallback={verifyCallback}
                          onloadCallback={recaptchaLoaded}
                          expiredCallback={expiredCallback}
                        />
                      </div>

                      <div className='field'>
                        <button disabled={formValues.name && formValues.email && formValues.message ? false : 'disabled'} className={styles.customButton} type='submit'>
                    Send
                        </button>
                      </div>
                      {formErrors && (
                        <div className={styles.errors}><p>Unable to submit form. Please make sure all of your fields are filled out.</p></div>
                      )}

                    </form>
                  )}
                </div>
              </div>
              {pageSidebar.length ? (
                <div className={styles.contentSideBar} >
                  <nav className={styles.sideBarNav}>
                    <ul>
                      { pageSidebar[0].links.map(link => (
                        <li key={link._key}>
                          {(link.siteLink.includes('https') || link.siteLink.includes('http')) ? (
                            <a href={link.siteLink} target='_blank' rel='noopener noreferrer'>
                              <span>{link.title}</span>
                              <span className={styles.subNavSubTitle}>{link.subTitle}</span>
                            </a>
                          ) : (
                            <Link to={link.siteLink}>
                              <span>{link.title}</span>
                              <span className={styles.subNavSubTitle}>{link.subTitle}</span>
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>

                  {pageSidebar[0].siteAddress ? (
                    <div>
                      <address>
                        <div className='vcard'>
                          <div className={styles.sectionTitle}>Address:</div>
                          <div className={`org ${styles.visuallyHidden}`}>{pageSidebar[0].siteAddress.companyName}</div>
                          <div className={styles.adr}>
                            <div className='street-address'>{pageSidebar[0].siteAddress.street}</div>
                            <span className='locality'>{pageSidebar[0].siteAddress.city}</span>,{' '}
                            <span className='region'>{pageSidebar[0].siteAddress.state}</span>{' '}
                            <span className='postal-code'>{pageSidebar[0].siteAddress.zip}</span>
                            <span className={`country-name ${styles.visuallyHidden}`}>United States</span>
                          </div>
                          <div className={styles.mapLink}>
                            <a href={pageSidebar[0].siteAddress.mapLink} target='_blank'>Map &amp; Directions</a>
                          </div>
                          <div className={styles.sectionTitle}>Phone:</div>
                          <div className={styles.tel}>
                            <a href={`tel:${pageSidebar[0].siteAddress.phone}`}>{pageSidebar[0].siteAddress.phone}</a>
                          </div>
                        </div>
                      </address>
                    </div>
                  ) : (
                    ''
                  )}

                  {pageSidebar[0].siteHours ? (
                    <div className={styles.topFooterContent}>
                      <div className={styles.sectionTitle}>Hours:</div>
                      <div className={styles.topFooterHours}>
                        <div>Mon: {pageSidebar[0].siteHours.monHours}</div>
                        <div>Tue: {pageSidebar[0].siteHours.tueHours}</div>
                        <div>Wed: {pageSidebar[0].siteHours.wedHours}</div>
                        <div>Thur: {pageSidebar[0].siteHours.thurHours}</div>
                        <div>Fri: {pageSidebar[0].siteHours.friHours}</div>
                        <div>Sat: {pageSidebar[0].siteHours.satHours}</div>
                        <div>Sun: {pageSidebar[0].siteHours.sunHours}</div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                </div>
              ) : (
                ''
              )}

            </div>
          </div>
        </Container>
      </article>

    </>
  )
}
export default Page
