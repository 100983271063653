import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../containers/layout'
import Page from '../components/contact-page'
import SEO from '../components/seo'

export const query = graphql`
  query($slug: String!) {
    sanityContact(slug: { current: { eq: $slug } }) {
      title
      subTitle
      _rawBody
      mainImage {
        asset {
          fluid(maxWidth:1900) {
            ...GatsbySanityImageFluid
          }
          fixed(width: 1900) {
            ...GatsbySanityImageFixed
          }
        }
      }
      formSettings {
        thankyou
        emailto
        subject
        enableForm
      }
      pageSidebar {
      _id
      links {
        _key
        title
        subTitle
        siteLink
        }
        siteAddress {
          companyName
          street
          city
          state
          zip
          mapLink
          phone
        }
      siteHours {
        monHours
        tueHours
        wedHours
        thurHours
        friHours
        satHours
        sunHours
      }
      }
      seoSettings {
        title
        description
      }
    }
  }
`

const ContactPage = ({data: {sanityContact: page}}) => (

  <Layout mainImage={page.mainImage} title={page.title}>
    {page.seoSettings.title && page.seoSettings.description &&
        (<SEO title={page.seoSettings.title} description={page.seoSettings.description} />
        )}
    <Page
      title={page.title}
      subTitle={page.subTitle}
      _rawBody={page._rawBody}
      mainImage={page.mainImage}
      pageSidebar={page.pageSidebar}
      thankYou={page.formSettings.thankyou}
      emailto={page.formSettings.emailto}
      subject={page.formSettings.subject}
      enableForm={page.formSettings.enableForm}
    />

  </Layout>
)

export default ContactPage
